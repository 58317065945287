import { NullOrType } from '@/types'
import { isOnServer } from './ssr'
import { GOOGLE_ANALYTICS_4_DESTINATION } from './constants'

export * from './constants'

export function camelCaseToKebabCase(input: string): string {
  return input.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

export function keybabCaseToCamelCase(input: string): string {
  return input.replace(/-./g, (word) => word[1].toUpperCase())
}

export function underScoreCaseToCamelCase(input: string): string {
  return input.replace(/_./g, (word) => word[1].toUpperCase())
}

export function JSONToCssVariables(data: { [key: string]: string }): string {
  return Object.keys(data)
    .map((key) => `--${camelCaseToKebabCase(key)}: ${data[key]};`)
    .join('\n')
}

// function check is primitive type
export function isPrimitiveType(input: any) {
  return input !== Object(input)
}

// function check is object type
export function isObjectType(input: any) {
  return input !== null && typeof input === 'object' && !Array.isArray(input)
}

export function cloneDeep<T>(input: T): T {
  if (isPrimitiveType(input)) {
    return input
  }
  return JSON.parse(JSON.stringify(input))
}

export function removeProtocol(path: string) {
  return path.replace(/http(s)?:/, '')
}

export function debounce(
  func: (...argumentsArray: any[]) => any,
  wait: number,
  immediate?: boolean
) {
  let timeout: any,
    args: any,
    context: any,
    timestamp: number,
    result: any,
    called: boolean
  if (null == wait) wait = 100

  function later() {
    const last = Date.now() - timestamp

    if (last < wait && last >= 0) {
      timeout = setTimeout(() => {
        later()
      }, wait - last)
    } else {
      timeout = null
      if (!called) {
        result = func.apply(context, args)
      }

      context = args = null
    }
  }

  const debounced = function (this: any, ...argArray: any[]) {
    context = this
    args = argArray
    timestamp = Date.now()
    const callNow = immediate && !timeout
    if (!timeout)
      timeout = setTimeout(() => {
        later()
      }, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
      called = true
    } else {
      called = false
    }

    return result
  }

  debounced.clear = function () {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
  }

  debounced.flush = function () {
    if (timeout) {
      result = func.apply(context, args)
      context = args = null

      clearTimeout(timeout)
      timeout = null
    }
  }

  return debounced
}

export function getQueryString(param: string) {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search.slice(1))
  return params.get(param)
}

export function getSameElementOfTwoArray<T>(array1: T[], array2: T[]) {
  return array1.filter((item) => array2.includes(item))
}

export function anyToString(input: any) {
  if (typeof input === 'string') return input
  if (typeof input === undefined) return ''
  if (typeof input === 'object') return JSON.stringify(input)
  return input.toString()
}

export function getCurrentTimeStamp() {
  return performance.now()
}

export function isEmail(email: string) {
  const regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
  if (!regex.test(email)) {
    return false
  } else {
    return true
  }
}

// run the function funcInput and only return the result after a minimum time
export function minimumDelay<T>(
  funcInput: () => Promise<T>,
  minimumTime: number = 300
): () => Promise<NullOrType<T>> {
  return (...args: any[]) => {
    return new Promise((resolve, reject) => {
      // only wait on client side
      let waiting = !isOnServer && true
      let running = true
      let result: T | undefined = undefined
      setTimeout(() => {
        waiting = false
        if (!running) {
          return resolve(result)
        }
      }, minimumTime)
      funcInput(...args)
        .then((res) => {
          result = res
          if (!waiting) {
            return resolve(result)
          }
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          running = false
        })
    })
  }
}

export function replaceAllQuotationMarks(str: string) {
  return str.replaceAll('"', "'")
}

export function isTruelyValue(
  value: number | string | boolean | undefined | null | Array<any>
) {
  return value === true || value === 'true' || (value && value > 0)
}

export function omitFalsyValue(object) {
  Object.keys(object)
    .filter((key) => !object[key])
    .forEach((key) => delete object[key])
  return object
}

export function getNumberGreaterThanNearest(array: number[], number: number) {
  array.sort()
  for (let i = 0; i < array.length; i++) {
    if (array[i] >= number) return array[i]
  }
}

export function unique(input: Array<any>) {
  if (!Array.isArray(input)) return input
  return [...new Set(input)]
}
export function convertSnakeCaseToTitleCase(value: string) {
  return value.replace(/^_*(.)|_+(.)/g, (value, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  )
}

export function destinationIntegrations(destinations: string[]) {
  if (!destinations?.length) {
    return {
      integrations: {
        All: true,
      },
    }
  }
  const integrations: {
    [key: string]: boolean
  } = { All: false, [GOOGLE_ANALYTICS_4_DESTINATION]: false }

  destinations.forEach((destination) => (integrations[destination] = true))

  return {
    integrations,
  }
}

export function removePropertiesStartWithPrefix(
  object: { [key: string]: string },
  prefix: string
) {
  const newObject: { [key: string]: string } = {}

  for (const key in object) {
    if (!key.startsWith(prefix) && object[key] != null && object[key] != '') {
      newObject[key] = object[key]
    }
  }

  return newObject
}

export function areArraysEqual(array1: string[] | [], array2: string[] | []) {
  // Check the length of two arrays
  if (array1.length !== array2.length) {
    return false
  }
  // Compare each element of two arrays
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false
    }
  }
  return true
}

export function removePropertiesObject(
  object: { [key: string]: string },
  property: string
) {
  const newObject: { [key: string]: string } = {}
  for (const key in object) {
    if (key != property) {
      newObject[key] = object[key]
    }
  }
  return newObject
}

export function cleanGraphQLResponse(input: any) {
  if (!input) return null
  if (isPrimitiveType(input)) return input

  const output = {} as any
  Object.keys(input).forEach((key) => {
    if (key === 'nodes') {
      output[key] = Object.values(cleanGraphQLResponse(input[key]))
    } else if (input[key] && input[key].edges) {
      output[key] = input[key].edges.map((edge: any) => {
        if (edge.cursor) {
          return cleanGraphQLResponse(
            Object.assign({}, edge.node, { __cursor: edge.cursor })
          )
        }
        return cleanGraphQLResponse(edge.node)
      })
      if (input[key].pageInfo) {
        output[`__${key}__pageInfo`] = input[key].pageInfo
      }
      if (input[key].filters) {
        output[`__${key}__filters`] = input[key].filters
      }
    } else if (isObjectType(input[key])) {
      output[key] = cleanGraphQLResponse(input[key])
    } else if (key !== '__typename') {
      output[key] = input[key]
    }
  })

  return output
}

export function removeTrailingPeriod(text: string) {
  if (text.endsWith('.')) {
    return text.slice(0, -1)
  }
  return text
}
